import React, {Fragment, useState} from "react";
// import Map from "./Map";
import MainForm from "./MainForm";
import locpin from "../assets/images/locpin.png";

import jaffna from "../assets/images/designations/jaffna.png";
import kandy from "../assets/images/designations/kandy.png";
import wilpathtu from "../assets/images/designations/wilpathtu.png";
import trinkomalee from "../assets/images/designations/trinkomalee.png";
import kalpitiya from "../assets/images/designations/kalpitiya.png";
import anurdhapura from "../assets/images/designations/anurdhapura.png";
import habarana from "../assets/images/designations/habarana.png";
import pasikuda from "../assets/images/designations/pasikuda.png";
import aruganbay from "../assets/images/designations/aruganbay.png";
import ella from "../assets/images/designations/ella.png";
import nuwaraeliya from "../assets/images/designations/nuwaraeliya.png";
import colombo from "../assets/images/designations/colombo.png";
import benthota from "../assets/images/designations/benthota.png";
import hikkaduwa from "../assets/images/designations/hikkaduwa.png";
import galle from "../assets/images/designations/galle.png";
import katharagama from "../assets/images/designations/katharagama.png";
import yala from "../assets/images/designations/yala.png";
import TomTomMap from "./TomTomMap";
// import MetaTags from "react-meta-tags";

const Home = () => {

    const [destinations, setDestinations] = useState([
        {id: 1, location: 'Jaffna', img: jaffna, lat: 9.666664, lng: 80.0, ico: locpin}, //JAFFNA
        {id: 2, location: 'Kandy', img: kandy, lat: 7.290572, lng: 80.633728, ico: locpin}, //kandy
        {id: 3, location: 'Wilpattu', img: wilpathtu, lat: 8.456372, lng: 80.047638, ico: locpin},  //wilpattu
        {id: 4, location: 'Trinkomalee', img: trinkomalee, lat: 8.592200, lng: 81.196793, ico: locpin},  //trinkomalee
        {id: 5, location: 'Kalpitiya', img: kalpitiya, lat: 8.229528, lng: 79.759614, ico: locpin},  //kalpitiya
        {id: 6, location: 'Anuradhapura', img: anurdhapura, lat: 8.3451852, lng: 80.3881333, ico: locpin},  //anuradhapura
        {id: 7, location: 'Habarana', img: habarana, lat: 8.0322, lng: 80.7519, ico: locpin},  //habarana
        {id: 8, location: 'Pasikuda', img: pasikuda, lat: 7.9228, lng: 81.5651, ico: locpin},  //pasikuda
        {id: 9, location: 'Arugambay', img: aruganbay, lat: 6.8404, lng: 81.8368, ico: locpin},  //arugambay
        {id: 10, location: 'Ella', img: ella, lat: 6.8667, lng: 81.0466, ico: locpin},  //ella
        {id: 11, location: 'Nuwara Eliya', img: nuwaraeliya, lat: 6.9497, lng: 80.7891, ico: locpin},  //nuwara eliya
        {id: 12, location: 'Colombo', img: colombo, lat: 6.927079, lng: 79.861244, ico: locpin},  //colombo
        {id: 13, location: 'Benthota', img: benthota, lat: 6.4189, lng: 80.0060, ico: locpin},  //benthota
        {id: 14, location: 'Hikkaduwa', img: hikkaduwa, lat: 6.1395, lng: 80.1063, ico: locpin},  //hikkaduwa
        {id: 15, location: 'Galle', img: galle, lat: 6.053519, lng: 80.220978, ico: locpin},  //galle
        {id: 16, location: 'Kataragama', img: katharagama, lat: 6.4135, lng: 81.3326, ico: locpin},  //kataragama
        {id: 17, location: 'Yala', img: yala, lat: 6.5011, lng: 81.4513, ico: locpin},  //yala
    ]);

    const [selectedDestinations, setSelectedDestinations] = useState([])
    const updateDestinations = (list) => {
        setSelectedDestinations(list)
    }
    return (
        <Fragment>

            <div className="body ">
                <div className="row body-inner">
                    <div className="map  col-lg-6  col-12 order-lg-2">
                        {/*<Map destincations={destinations} selectedDestinations={selectedDestinations}/>*/}
                        <TomTomMap destincations={destinations} selectedDestinations={selectedDestinations}/>
                    </div>
                    <div className="calculation-section col-lg-6 col-12 order-lg-1">
                        <MainForm destincations={destinations} setSelectedDestinations={setSelectedDestinations} selectedDestinations={selectedDestinations}/>
                    </div>
                </div>

            </div>
        </Fragment>
    );
};

export default Home;
