import React, {Fragment, useState} from "react";
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';

// images
import img0 from '../assets/images/designations/doneloc.png';
import img1 from '../assets/images/designations/loc1.png';
import img2 from '../assets/images/designations/loc2.png';
import img3 from '../assets/images/designations/loc3.png';
import img4 from '../assets/images/designations/loc4.png';
import img5 from '../assets/images/designations/loc5.png';
import img6 from '../assets/images/designations/loc6.png';
import img7 from '../assets/images/designations/loc7.png';
import img8 from '../assets/images/designations/loc8.png';
import img9 from '../assets/images/designations/loc9.png';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Cookies from 'universal-cookie';
import Autocomplete from "@mui/joy/Autocomplete";
import TextField from "@mui/material/TextField";

const MainForm = (props) => {
    const cookies = new Cookies();
    const defhoteltype = cookies.get('hoteltype') ? cookies.get('hoteltype'):'Budget';
    const defdesignations = cookies.get('designations') ? cookies.get('designations') : [];
    const defname = cookies.get('name') ? cookies.get('name') : '';
    const deftel = cookies.get('tel') ? cookies.get('tel') : '';
    const defregion = cookies.get('region') ? cookies.get('region') : '';
    const defemail = cookies.get('email') ? cookies.get('email') : '';

    // hotel-type
    const whatsapplink = 'https://wa.me/94759048421?text=';
    const hotelTypeList = [
        'Budget',
        '3 Star',
        '5 star'
    ];
    const [hotelType, setHotelType] = React.useState(defhoteltype);
    // designations
    const [designations, setDesignations] = React.useState(defdesignations);

    const locationList = [
        {location: 'Anuradhapura', img: img1},
        {location: 'Habarana', img: img1},
        {location: 'Kandy', img: img2},
        {location: 'Colombo', img: img3},
        {location: 'Nuwara', img: img3},
        {location: 'Ella', img: img4},
        {location: 'Kataragama', img: img5},
        {location: 'Yala', img: img6},
        {location: 'Galle', img: img7},
        {location: 'Hikkaduwa', img: img7},
        {location: 'Trinkcomalee', img: img8},
        {location: 'Jafna', img: img9},
    ];
    // form

    const countryList = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas (the)",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory (the)",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands (the)",
        "Central African Republic (the)",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands (the)",
        "Colombia",
        "Comoros (the)",
        "Congo (the Democratic Republic of the)",
        "Congo (the)",
        "Cook Islands (the)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic (the)",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (the) [Malvinas]",
        "Faroe Islands (the)",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories (the)",
        "Gabon",
        "Gambia (the)",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (the)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic (the)",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands (the)",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova (the Republic of)",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands (the)",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger (the)",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands (the)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines (the)",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation (the)",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan (the)",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands (the)",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates (the)",
        "United Kingdom of Great Britain and Northern Ireland (the)",
        "United States Minor Outlying Islands (the)",
        "United States of America (the)",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
    ];

    const [yNameerrorcheck, setYNameerrorcheck] = useState(false);
    const [regionerrorcheck, setRegionerrorcheck] = useState(false);
    const [telerrorcheck, setTelerrorcheck] = useState(false);
    const [emailerrorcheck, setEmailerrorcheck] = useState(false);

    const [yNameerror, setYNameerror] = useState("");
    const [regionerror, setRegionerror] = useState("");
    const [telerror, setTelerror] = useState("");
    const [emailerror, setEmailerror] = useState("");
    const [whatsappmsg, setwhatsappmsg] = useState("");

    const [formName, setFormName] = useState(defname);
    const [formContact, setFormContact] = useState(deftel);
    const [formRegion, setFormRegion] = useState(defregion);
    const [formEmail, setFormEmail] = useState(defemail);
    const [quotationValidation, setquotationValidation] = useState(false);
    const [QoutationVal, setQoutationVal] = useState(0);
    const [QoutationValshow, setQoutationValshow] = useState(false);


    function phonevalidation(telNumber) {
        let errorMessage = "";
        let isValid = true;

        if (typeof telNumber !== "undefined") {

            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(telNumber)) {
                isValid = false;
                errorMessage = "Please enter only number.";
            }
        } else {
            isValid = false;
            errorMessage = "Please enter only number.";
        }
        if (!isValid) {
            setTelerrorcheck(true);
            setTelerror(<div className="frm-error">{errorMessage}</div>);
        } else {
            setTelerrorcheck(false);
            setTelerror('');
        }
    }

    function isNotEmptyvalidation(val, typee) {
        let error = false;
        if (typeof val !== "undefined") {
            if (val === '') {
                error = true;
            }
        } else {
            error = true;
        }
        if (error) {
            if (typee === 'region') {
                setRegionerrorcheck(true);
                setRegionerror(<div className="frm-error">please enter your region</div>);
            }
            if (typee === 'yname') {
                setYNameerrorcheck(true);
                setYNameerror(<div className="frm-error">please enter your name</div>);
            }
        } else {

            if (typee === 'region') {
                setRegionerrorcheck(false);
                setRegionerror('');
            }
            if (typee === 'yname') {
                setYNameerrorcheck(false);
                setYNameerror('');
            }
        }

    }

    function emailvalidation(email) {
        let errorMessage = "";
        let isValid = true;
        if (typeof email !== "undefined") {

            var pattern = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
            if (!pattern.test(email)) {
                isValid = false;
                errorMessage = "Please enter correct email.";
            }
        }
        if (!isValid) {
            setEmailerrorcheck(true);
            setEmailerror(<div className="frm-error">{errorMessage}</div>);
        } else {
            setEmailerrorcheck(false);
            setEmailerror('');
        }
        if (email === '') {
            setEmailerrorcheck(false);
            setEmailerror('');
        }
    }

    const handleChangeContactblur = event => {
        phonevalidation(event.target.value);
    };
    const handleChangeYNameblur = event => {
        isNotEmptyvalidation(event.target.value, 'yname')
    }
    const handleChangeRegionblur = event => {
        isNotEmptyvalidation(event.target.value, 'region')
    }
    const handleChangeEmailblur = event => {
        emailvalidation(event.target.value);
    }

    const handleChangeName = event => {
        setFormName(event.target.value);
    };
    const handleChangeContact = event => {
        setFormContact(event.target.value);
    };
    const handleChangeRegion = event => {
        setFormRegion(event.target.value);
        if (typeof event.target.value !== "undefined") {

        }
    };
    const handleChangeEmail = event => {
        setFormEmail(event.target.value);
    };


    function quoteGeneratorValidation() {
        phonevalidation(formContact);
        isNotEmptyvalidation(formName, 'yname');
        isNotEmptyvalidation(formRegion, 'region');
        emailvalidation(formEmail);
        if (yNameerrorcheck || regionerrorcheck || telerrorcheck || emailerrorcheck || designations.length === 0) {
            setquotationValidation(false);
        } else {
            setquotationValidation(true);
        }

    }

    const genarateQuotation = () => {
        let formIsValid = false;
        // quoteGeneratorValidation();
        phonevalidation(formContact);
        isNotEmptyvalidation(formName, 'yname');
        isNotEmptyvalidation(formRegion, 'region');
        emailvalidation(formEmail);
        if (yNameerrorcheck || regionerrorcheck || telerrorcheck || emailerrorcheck || designations.length === 0) {
            formIsValid = false;
        } else {
            formIsValid = true ;
        }


        if (formIsValid) {

            let hotelChargePerDay = 0;
            let quotation = 0;
            const travelChargeForDay = 90;
            const destinationscount = designations.length;
            if (hotelType === 'Budget') {
                hotelChargePerDay = 20;
            }
            if (hotelType === '3 Star') {
                hotelChargePerDay = 100;
            }
            if (hotelType === '5 star') {
                hotelChargePerDay = 200;
            }
            let fullTravelQuotation = travelChargeForDay * destinationscount;
            let fullHotelChargeQuotation = hotelChargePerDay * destinationscount;
            quotation = fullTravelQuotation + fullHotelChargeQuotation;
            setQoutationVal(quotation);
            setQoutationValshow(true);
            const element = document.getElementById('whatsaplinkf');
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
            let finalDestinationList = ""

            designations.map(d => {
                if(finalDestinationList) {
                    finalDestinationList = finalDestinationList +', ' + d
                } else {
                    finalDestinationList = d
                }
            })


            const msg = "Hi, \nI'm " + formName + ". I would like to visit " + finalDestinationList + " And I wish to stay at " + hotelType + " Hotels. My Quotation price is " + quotation + "$. I like to discuss more details with you \nThank you.";


            setwhatsappmsg(whatsapplink + encodeURIComponent(msg));


            cookies.set('hoteltype', hotelType, {path: '/', maxAge: 2592000});
            cookies.set('designations', designations.toString(), {path: '/'});
            cookies.set('name', formName, {path: '/'});
            cookies.set('tel', formContact, {path: '/'});
            cookies.set('region', formRegion, {path: '/'});
            cookies.set('email', formEmail, {path: '/'});





        } else {
            console.log('validation faild ')
        }
    }

    const updateDestination = (action, location) => {
        let selectedDestinations = [...props.selectedDestinations]
        switch (action) {
            case 'REMOVE':
                setDesignations((val) => val.filter((text) => text !== location));
                let idx = selectedDestinations.indexOf(location)
                if (idx > -1) { // only splice array when item is found
                    selectedDestinations.splice(idx, 1); // 2nd parameter means remove one item only
                }
                props.setSelectedDestinations(selectedDestinations);
                break;
            default:
                setDesignations((val) => [...val, location]);
                selectedDestinations.push(location)
                props.setSelectedDestinations(selectedDestinations);
                break;
        }
    }


    return (
        <Fragment>
            <div className="main-form">
                <div className="hotel-type-container">
                    <p className="hotel-type-title">Select Hotel Type*</p>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <RadioGroup
                            orientation="horizontal"
                            aria-labelledby="segmented-controls-example"
                            name="hotelType"
                            value={hotelType}
                            onChange={(event) => (setHotelType(event.target.value))}
                            sx={{
                                minHeight: 52,
                                padding: '0',
                                borderRadius: 'md',
                                bgcolor: 'white',
                                '--RadioGroup-gap': '9px',
                                '--Radio-action-radius': '8px',
                            }}
                        >
                            {hotelTypeList.map((item) => (
                                <Radio
                                    key={item}
                                    color="#F3F3F3"
                                    value={item}
                                    disableIcon
                                    label={item}
                                    variant="plain"
                                    sx={{
                                        px: 2,
                                        alignItems: 'center',
                                        bgcolor: '#F3F3F3',
                                        borderRadius: 0,
                                        height: 29,
                                        width: 71,
                                        fontFamily: 'Inter',
                                        fontSize: 12,
                                        lineHeight: 15,
                                        fontWeight: 500,
                                        color: '#6BB052',
                                    }}
                                    slotProps={{
                                        action: ({checked}) => ({
                                            sx: {
                                                ...(checked && {
                                                    bgcolor: '#6EDE47',
                                                    boxShadow: 'md',
                                                    borderRadius: 0,
                                                    fontWeight: 700,
                                                    color: '#085C25',
                                                    '&:hover': {
                                                        bgcolor: '#5ED733FF',
                                                        borderRadius: 0,
                                                    },
                                                }),
                                            },
                                        }),
                                        label: ({checked}) => ({
                                            sx: {
                                                ...(checked && {
                                                    fontWeight: 700,
                                                    color: '#085C25',
                                                    '&:hover': {
                                                        fontWeight: 700,
                                                        color: '#085C25',
                                                    },
                                                }),
                                            },
                                        }),
                                    }}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </div>
                <div className="designations-container">
                    <p className="designations-title">Select Designations*</p>
                    <div className="designations-holder">
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '9px',
                                '--List-item-radius': '18px',
                                '--List-item-minHeight': '36px',
                            }}
                        >
                            {props.destincations && props.destincations.map(({location, img, index}) => (
                                    <ListItem className="designation-item" key={location}
                                              sx={{
                                                  bgcolor: '#ECECEC',
                                                  minHeight: 36,

                                                  marginX: 0,
                                                  paddingLeft: '3px',
                                                  paddingTop: '2px',
                                                  paddingBottom: '2px',
                                              }}
                                    >
                                        {designations.includes(location) && (
                                            <img src={img0} alt=""/>
                                        )}
                                        {!designations.includes(location) && (
                                            <img src={img} alt=""/>
                                        )}
                                        <Checkbox
                                            size="sm"
                                            disabled={index === 0}
                                            disableIcon
                                            overlay
                                            label={location}
                                            checked={designations.includes(location)}
                                            variant={designations.includes(location) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    // setDesignations((val) => [...val, location]);
                                                    updateDestination('ADD', location)
                                                } else {
                                                    // setDesignations((val) => val.filter((text) => text !== location));
                                                    updateDestination('REMOVE', location)
                                                }
                                            }}
                                            sx={{
                                                px: 2,
                                                alignItems: 'center',
                                                borderRadius: 0,
                                                height: 29,
                                                minWidth: 'calc(100% - 32px)',
                                                fontFamily: 'Inter',
                                                fontSize: 12,
                                                lineHeight: 15,
                                                fontWeight: 500,
                                                color: '#085C25',
                                                textAlign: 'center',
                                                bgcolor: 'unset',
                                            }}
                                            slotProps={{
                                                action: ({checked}) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </div>
                </div>
                <div className="details-container">
                    <p className="details-title">Please Enter Your Details*</p>
                    <Form>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Control type="text" onChange={handleChangeName} value={formName}
                                          onBlur={handleChangeYNameblur}
                                          placeholder="Your Name"/>
                            {yNameerror}
                        </Form.Group>
                        <div className="form-inner-sub">
                            <div className="mb-3">
                                <Form.Group controlId="formContact">
                                    <Form.Control type="tel" onChange={handleChangeContact}
                                                  onBlur={handleChangeContactblur}
                                                  value={formContact}
                                                  placeholder="Contact Number"/>
                                    {telerror}
                                </Form.Group>
                            </div>
                            <div className="mb-3">
                                <Form.Group controlId="formRegion">
                                    <Form.Control type="text" onChange={handleChangeRegion} value={formRegion}
                                                  onBlur={handleChangeRegionblur}
                                                  placeholder="Your Region"/>

                                    {/*<Autocomplete*/}
                                    {/*    disablePortal*/}
                                    {/*    id="combo-box-demo"*/}
                                    {/*    options={countryList}*/}
                                    {/*    sx={{ width: 300 }}*/}
                                    {/*    onChange={handleChangeRegion} value={formRegion}*/}
                                    {/*    renderInput={(params) => <TextField {...params} label="Movie" />}*/}
                                    {/*/>*/}

                                    {regionerror}
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Control type="email" onChange={handleChangeEmail} onBlur={handleChangeEmailblur}
                                          value={formEmail}
                                          placeholder="Enter email (optional)"/>
                            {emailerror}
                        </Form.Group>
                        <Button className="genarate-btn" onClick={genarateQuotation} variant="primary" type="button">
                            Generate the average cost
                        </Button>
                    </Form>
                </div>
                {!QoutationValshow ? (
                    <div className="result-container-hidden"></div>
                ) : (
                    <div className="result-container">
                        <div className="result-container-txt-contain">
                            <p className="result-container-txt">Avg. budget</p>
                        </div>
                        <div className="result-container-price-contain">
                            <p className="result-container-price-contain-txt">USD {QoutationVal}.0</p>
                        </div>
                    </div>
                )}
                {!QoutationValshow ? (
                    <div className="contact-hidden">
                        {/*<p className="contact-p-hidden"  id="whatsaplink"></p>*/}
                    </div>
                ) : (
                    <div className="contact">
                        <a className="whatsapliink" id="whatsaplink"
                           href={whatsappmsg} target="_blank" rel="noreferrer">Please
                            contact
                            our agent for customizations and negotiations</a>
                    </div>
                )}
                <div className="contact-hidden">
                    <p className="contact-p-hidden"  id="whatsaplinkf"></p>
                </div>
            </div>
        </Fragment>
    );
};

export default MainForm;
