// import logo from './logo.svg';
import * as React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./pages/Header";
// import Footer from "./pages/Footer";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';

function App() {
  return (
      <React.StrictMode>
          <StyledEngineProvider injectFirst>
              <CssVarsProvider>
                  <div className="App">
                      <Header />
                      <BrowserRouter>
                          <Routes>
                              <Route path="/" element={<Home />}>
                                  <Route index element={<Home />} />
                                  {/*<Route path="blogs" element={<Blogs />} />*/}
                                  {/*<Route path="contact" element={<Contact />} />*/}
                                  {/*<Route path="*" element={<NoPage />} />*/}
                              </Route>
                          </Routes>
                      </BrowserRouter>
                      {/*<Footer />*/}
                      {/*<Switch>*/}
                      {/*  <Route*/}
                      {/*      exact*/}
                      {/*      path={process.env.PUBLIC_URL + "/"}*/}
                      {/*      components={Home}*/}
                      {/*  />*/}
                      {/*</Switch>*/}
                  </div>
              </CssVarsProvider>
          </StyledEngineProvider>
      </React.StrictMode>
  );
}

export default App;
