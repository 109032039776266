import React, { Fragment } from "react";
// import MetaTags from "react-meta-tags";
import logo from '../assets/images/ZaaraMainLogo.png';

const Header = () => {
  return (
    <Fragment>

        <div className="header">
            <div className="header-inner">
                <img src={logo} alt="logo"/>
            </div>
        </div>
    </Fragment>
  );
};

export default Header;
