import React, {useEffect, useRef, useState} from "react";
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import tt from '@tomtom-international/web-sdk-maps';
import Input from "@mui/material/Input";

const MAX_ZOOM = 100;

const TomTomMap = (props) => {
    const mapElement = useRef();
    const [mapLongitude, setMapLongitude] = useState(80.633728);
    const [mapLatitude, setMapLatitude] = useState(7.590572);
    const [mapZoom, setMapZoom] = useState(6.5);
    const [map, setMap] = useState({});
    const [markers, setMarkers] = useState([]);

    const speedyPizzaCoordinates1 = [80.633728, 7.290572]
    const speedyPizzaCoordinates2 = [80.0, 9.666664]

    const increaseZoom = () => {
        if (mapZoom < MAX_ZOOM) {
            setMapZoom(mapZoom + 1);
        }
    };

    const decreaseZoom = () => {
        if (mapZoom > 1) {
            setMapZoom(mapZoom - 1);
        }
    };

    const updateMap = () => {
        map.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
        map.setZoom(mapZoom);
    };

    useEffect(() => {
        let map = tt.map({
            key: "QBBbPnw490ACko1s4mmZE0e3Vt2DnoTP",
            container: mapElement.current,
            center: [mapLongitude, mapLatitude],
            zoom: mapZoom,
            language: "en-US"
        });
        setMap(map);
        // new tt.Marker().setLngLat(speedyPizzaCoordinates1).addTo(map)
        // new tt.Marker().setLngLat(speedyPizzaCoordinates2).addTo(map)
        // return () => map.remove();
    }, []);
    useEffect(() => {

        let markerss = []

        markers.map(m => {
            m.marker.remove()
        })

        props.destincations.map(({location, lat, lng, ico}) => {
            if (props.selectedDestinations.indexOf(location) > -1) {
                let marker = new tt.Marker().setLngLat([lng, lat]).addTo(map);
                markerss.push({location: location, marker: marker});
            }
        })

        setMarkers(markerss);
    }, [props.selectedDestinations])
    return (
        <div ref={mapElement} className="mapDiv"></div>
    )
}

export default TomTomMap;
